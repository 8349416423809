.App {
  align-items: stretch;

  .content {
    background: var(--BackgroundColor, #fbfbff);
    min-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.text-link {
  color: #2f2a89;

  &:hover {
    color: lighten(#2f2a89, 5%);
  }
}

.error {
  color: rgb(245, 67, 67);
  margin-top: 10px;
  font-size: 12px;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-disabled {
  cursor: not-allowed;
}
