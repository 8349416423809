.colorStyle {
  padding: 0;
  border: none;
  width: 70px;
  height: 40px;
  display: flex;
  align-items: center;
}

.colorStyle::-moz-color-swatch {
  border: none;
}

.colorStyle::-webkit-color-swatch-wrapper {
  padding: 0;
  border-radius: 0;
}

.colorStyle::-webkit-color-swatch {
  border: none;
}
