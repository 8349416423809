.text1 {
  color: var(--TextColor, #383941);
  font-family: Poppins;
  font-size: 37px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.text2 {
  color: var(--TextColor, #383941);
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text3 {
  color: var(--SecondaryTextColor, #69768b);
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text4 {
  color: var(--SecondaryTextColor, #69768b);
  font-family: Poppins;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text5 {
  color: var(--PrimaryAccentColor, #2f2a89);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
