.alertContainer {
  .menu {
    min-width: 0;
  }

  .content {
    flex: 1;
    border-radius: 8px;
    background: var(--SecondaryBackgroundColor, #fff);
    box-shadow: 0px 60px 120px 0px rgba(38, 51, 77, 0.06);
    transition: all 200ms ease-in-out;

    .title {
      color: var(--TextColor, #383941);
      font-size: 24px;
      font-weight: 600;
    }
  }

  .hide {
    width: 0;
    opacity: 0;
    margin: 0;
    padding: 0;
    height: 0;
  }
}
