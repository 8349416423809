.container {
  background: var(--SecondaryBackgroundColor, #fff);
  padding: 30px 20px;

  .title {
    color: var(--TextColor, #383941);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
