.oilTank {
  border-radius: 16px;
  background: rgba(47, 42, 137, 0.06);
  border-radius: 2px;
  display: flex;
  align-items: flex-end;

  .tank {
    width: 132px;
    height: 153px;
    border-radius: 16px;
    background: var(--WidgetElementColor, #303956);
    overflow: hidden;
    position: relative;

    .wave {
      animation: animate 1s linear infinite;
      transition: height 2s linear;
      background-image: url("https://lh3.googleusercontent.com/u/0/d/13s88yMTVgpmgBxsOrW4hIvjjaDJn40I3=w1920-h969-iv1");
      background-position-x: 0;
      content: "";
      height: 50%;
      left: 0;
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }

  .sm {
    width: 110px;
    height: 130px;
  }
}

@keyframes animate {
  to {
    background-position-x: 200px;
  }
}

@keyframes animate2 {
  from {
    height: 0;
  }
  50% {
    height: 75%;
  }
  to {
    height: 0;
  }
}
