.wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.445);
  backdrop-filter: blur(5px);
  transition: visibility 210ms, opacity 200ms ease-out;
  .model {
    position: fixed;
    max-height: 100%;
    width: 700px;
    background-color: white;
    padding: 30px 20px;
    border-radius: 10px;
    transition: transform 200ms ease-out;
    overflow: auto;
    .close {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }
  }
  .model::-webkit-scrollbar {
    width: 8px;
  }

  .model::-webkit-scrollbar-track {
    background: rgb(128, 128, 128);
  }

  .model::-webkit-scrollbar-thumb {
    background-color: rgb(66, 66, 66);
    border-radius: 20px;
  }
}

.not-showing-modal {
  visibility: hidden;
  opacity: 0;
  .app-modal-container {
    transform: translateY(-100px);
  }
}

.showing-modal {
  visibility: visible;
  opacity: 1;
  .app-modal-container {
    transform: translateY(0px);
  }
}
