.tooltip {
  border-radius: 8px;
  border: 1px solid var(--formControlBorder, #eaecf3);
  background: var(--SecondaryBackgroundColor, #fff);
  box-shadow: 0px 60px 120px 0px rgba(38, 51, 77, 0.06);
  padding: 10px 15px;
  color: var(--TextColor, #383941);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  .critical {
    border-radius: 59px;
    background: #04b941;
    padding: 1px 14px;
    color: var(--TextColor, #f3f6fa);
    text-align: center;
    font-family: Inter, serif;
    font-size: 9px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
