.container {
  transition: 0.4s all ease-in;
}

.show {
  width: 100%;
  opacity: 1;
}

.hide {
  width: 0;
  opacity: 0;
}
