.tabButton {
  padding: 12px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border-radius: 39px;
  font-weight: 500;
  label {
    padding-left: 4px;
    padding-right: 20px;
  }
}

.inActive {
  background: var(--SecondaryBackgroundColor, #fff);
  box-shadow: 2px 10px 22px 0px rgba(38, 51, 77, 0.15);
  label {
    color: var(--SecondaryTextColor, #69768b);
  }
}

.active {
  background-color: #2f2a89;
  box-shadow: 2px 10px 22px 0px rgba(47, 42, 137, 0.27);
  label {
    color: #f3f6fa;
  }
}
