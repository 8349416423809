.navbarMobile {
  width: 100%;
  background: var(--SecondaryBackgroundColor, #fff);
  padding: 33px 18px;
  transition: all 200ms ease-in-out;
}

.menu {
  border: 1px solid var(--formControlBorder, #eaecf3);
  background: var(--SecondaryBackgroundColor, #fff);
  box-shadow: 0px 60px 120px 0px rgba(38, 51, 77, 0.06);
  height: 274px;
  overflow: hidden;
  position: relative;
  transition: all 200ms ease-in-out;

  .title {
    color: var(--TextColor, #383941);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
