.workflowSideMenuContainer {
  border-radius: 8px;
  background: var(--SecondaryBackgroundColor, #fff);
  box-shadow: 0px 60px 120px 0px rgba(38, 51, 77, 0.06);
  padding: 20px;
  width: 100%;
  max-width: 350px;
  opacity: 1;
  height: 100%;
  min-height: 85vh;
  overflow: hidden;
  transition: all 200ms ease-in;
  .title {
    color: var(--TextColor, #383941);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .item {
    border-bottom: solid 1px rgba(184, 184, 184, 0.3);
    padding-bottom: 10px;
    padding-top: 10px;
    cursor: pointer;

    &.selected {
      background-color: var(--PrimaryAccentColor, #2f2a89);
    }

    .workflowName {
      color: var(--TextColor, #383941);
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .selected {
      color: var(--BackgroundColor, #fbfbff);
      font-weight: 700;
    }
  }
}

.hide {
  width: 0;
  opacity: 0;
  margin: 0;
  padding: 0;
  height: 0;
}

.show {
  opacity: 1;
}

@media (max-width: 768px) {
  .workflowSideMenuContainer {
    max-width: none;
  }
}
