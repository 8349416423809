.btn {
  border: none;
  padding: 12px 32px;
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-family: Poppins;
  color: var(--SecondaryBackgroundColor, #fff);
  font-size: 16px;
  cursor: pointer;
  transition: background-color, color 100ms ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnPrimary {
  background-color: var(--PrimaryAccentColor, #2f2a89);
  box-shadow: -4px 10px 22px 0px rgba(47, 42, 137, 0.42);
  &:hover {
    background-color: var(--PrimaryAccentColor, #2f2a89);
  }
}

.btnDanger {
  background-color: var(--DangerColor, #bf4545);
  box-shadow: -4px 10px 22px 0px rgba(191, 69, 69, 0.42);
  &:hover {
    background-color: var(--DangerColor, #bf4545);
  }
}

.btnOutline {
  border: 1px solid var(--formControlBorder, #eaecf3);
  background: var(--SecondaryBackgroundColor, #fff);
  box-shadow: -105px 2px 120px 0px rgba(38, 51, 77, 0.06);
  color: var(--SecondaryTextColor, #69768b);
  &:hover {
    border: 1px solid var(--formControlBorder, #eaecf3);
    text-decoration: underline;
  }
}
