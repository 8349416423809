.container {
  .label {
    color: var(--TextColor, #383941);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
  }

  .textField {
    display: block;
    width: 100%;
    margin-top: 9px;
    padding: 13px 16px;
    border: 1px solid var(--formControlBorder, #eaecf3);
    border-radius: 10px;
    color: var(--SecondaryTextColor, #69768b);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    min-height: 200px;
    background: var(--SecondaryBackgroundColor, #fff);
  }

  :focus {
    outline: none !important;
  }
}
