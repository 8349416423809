.settingsContainer {
  .menu {
    min-width: 0;
  }

  .content {
    flex: 1;
    transition: all 200ms ease-in-out;

    .tableContainer {
      border-radius: 8px;
      background: var(--SecondaryBackgroundColor, #fff);
      box-shadow: 0px 60px 120px 0px rgba(38, 51, 77, 0.06);
    }
  }

  .hide {
    width: 0;
    opacity: 0;
    margin: 0;
    padding: 0;
    height: 0;
  }
}
