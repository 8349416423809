.widgetMoreInfoContainer {
  position: fixed;
  top: 0;
  right: 0;
  width: 567px;
  height: 100vh;
  border-radius: 20px;
  background: var(--SecondaryBackgroundColor, #fff);
  box-shadow: -105px 2px 120px 0px rgba(38, 51, 77, 0.06);
  z-index: 100;
  transition: right 400ms ease-in;
  padding: 20px;
  overflow-y: auto;

  .title {
    color: var(--TextColor, #383941);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .text01 {
    color: var(--SecondaryTextColor, #69768b);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .text02 {
    color: var(--widgetTextColor, #383941);
    font-family: Inter;
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .text03 {
    color: #9494a4;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .text04 {
    color: var(--SecondaryTextColor, #69768b);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .text05 {
    color: #5bcf82;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .text06 {
    color: var(--TextColor, #383941);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .chart {
    background: var(--BackgroundColor, #fbfbff);
    padding: 10px;
  }
}
