.title {
  color: var(--widgetTextColor, #383941);
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.subTitle {
  color: var(--SecondaryTextColor, #69768b);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}
