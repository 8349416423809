.iconButton {
  display: flex;
  align-items: center;
  width: 36px;
  height: 36px;

  .img {
    width: 100%;
    height: 100%;
  }

  .sidMenuOpenIcon {
    background-color: #2f2a89;
    position: absolute;
    right: 6px;
    z-index: -1;
    border-radius: 4px;
    display: flex;
    align-items: center;
  }
}
