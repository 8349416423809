.YELLOW {
  background-color: #ffc70f;
}

.RED {
  background-color: #cd000a;
}

.GREEN {
  background-color: #4fb402;
}

.BLUE {
  background-color: #0188cc;
}

.PURPLE {
  background-color: #86069a;
}

.selectedColor {
  border: 2px solid #080000;
}
