.navbar {
  position: fixed;
  width: 87px;
  background: var(--SecondaryBackgroundColor, #fff);
  height: 100vh;
  padding: 33px 18px;
  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .top {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}

.txt1 {
  color: var(--TextColor, #383941);
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
}

.txt2 {
  color: var(--SecondaryTextColor, #69768b);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
