body,
html {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}
* {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
