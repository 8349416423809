.cardContainer {
  border-radius: 8px;
  border: 1px solid var(--formControlBorder, #eaecf3);
  background: var(--SecondaryBackgroundColor, #fff);
  box-shadow: 0px 60px 120px 0px rgba(38, 51, 77, 0.06);
  padding: 20px;

  .text01 {
    color: var(--TextColor, #383941);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .text02 {
    color: var(--SecondaryTextColor, #69768b);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
