.progressBarContainer {
  .text {
    color: var(--SecondaryTextColor, #69768b);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .progressBar {
    width: 100%;
    height: 11px;
    border-radius: 54px;
    background: #f5f5f5;

    .progress {
      border-radius: 54px;
      background: #5bcf82;
      height: 11px;
    }
  }
}
