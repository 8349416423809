.widgetContainer {
  border-radius: 8px;
  border: 1px solid var(--formControlBorder, #eaecf3);
  background: var(--SecondaryBackgroundColor, #fbfbff);
  box-shadow: 0px 60px 120px 0px rgba(38, 51, 77, 0.06);
  height: 100%;
  cursor: pointer;

  .title {
    color: var(--widgetTextColor, #383941);
    font-size: 15px;
    font-weight: 500;
  }

  .value {
    font-size: 45px;
    font-weight: 500;
  }

  .unit {
    color: var(--SecondaryTextColor, #69768b);
    font-size: 15px;
    font-weight: 400;
  }

  .exchangeValue {
    color: var(--SecondaryTextColor, #69768b);
    font-size: 14px;
    font-weight: 400;
  }
}

.selectedWidget {
  border: 2px solid var(--formControlBorder, #2f2a89);
}
