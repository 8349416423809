.txt01 {
  color: var(--TextColor, #383941);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}

.border {
  border: 1px solid #e8e8e8;
  border-radius: 8px;
}
