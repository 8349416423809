.container {
  border-radius: 8px;
  background: var(--SecondaryBackgroundColor, #fff);
  box-shadow: 0px 60px 120px 0px rgba(38, 51, 77, 0.06);
}

.txt01 {
  color: var(--TextColor, #383941);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
