.widgetContainer {
  border-radius: 8px;
  border: 1px solid var(--formControlBorder, #eaecf3);
  background: var(--SecondaryBackgroundColor, #fff);
  box-shadow: 0px 60px 120px 0px rgba(38, 51, 77, 0.06);
  height: 100%;

  .title {
    color: var(--widgetTextColor, #383941);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .subTitle {
    color: var(--SecondaryTextColor, #69768b);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .value {
    font-family: Poppins;
    font-size: 64px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .unit {
    color: var(--SecondaryTextColor, #69768b);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 70px;
  }

  .exchangeValue {
    color: var(--SecondaryTextColor, #69768b);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
