.gauge {
  width: 100%;
  max-width: 250px;
  font-family: Poppins;
  font-size: 32px;
  color: #004033;
  position: relative;

  .gauge__body {
    width: 100%;
    height: 0;
    padding-bottom: 50%;
    background: #f3f2f8;
    position: relative;
    border-top-left-radius: 100% 200%;
    border-top-right-radius: 100% 200%;
    overflow: hidden;

    .gauge__fill {
      position: absolute;
      top: 100%;
      left: 0;
      width: inherit;
      height: 100%;
      background: #dfac60;
      transform-origin: center top;
      transform: rotate(0.25turn);
      transition: transform 0.2s ease-out;
    }

    .gauge__cover {
      width: 75%;
      height: 150%;
      background: #ffffff;
      border-radius: 50%;
      position: absolute;
      top: 25%;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 25%;
      box-sizing: border-box;

      .value {
        font-family: Poppins;
        font-size: 32px;
        font-weight: 500;
        line-height: normal;
      }

      .unit {
        color: var(--SecondaryTextColor, #69768b);
        font-family: Poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
      }

      .icon {
        width: 32px;
        height: 32px;
        display: flex;
        padding: 6px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .minValue {
    position: absolute;
    bottom: 45%;
    left: -70px;
    font-size: 14px;
    color: #69768b;
  }

  .maxValue {
    position: absolute;
    bottom: 45%;
    right: -70px;
    font-size: 14px;
    color: #69768b;
  }

  .markerMin {
    position: absolute;
    bottom: 20%;
    left: 10%;
    height: 50px;
    background-color: transparent;
    border-left: 3px dashed red;
    transform: rotate(130deg);
  }

  .markerMax {
    position: absolute;
    bottom: 20%;
    right: 10%;
    height: 50px;
    background-color: transparent;
    border-left: 3px dashed red;
    transform: rotate(50deg);
  }
}

.sm {
  width: 75%;

  .minValue {
    left: -45px;
  }

  .maxValue {
    right: -53px;
  }

  .markerMin {
    bottom: 20%;
    height: 35px;
  }

  .markerMax {
    bottom: 20%;
    height: 35px;
  }
}
