.trendsContainer {
  .menu {
    min-width: 0;
  }

  .content {
    flex: 1;
    transition: all 200ms ease-in-out;

    .filterCard {
      border-radius: 8px;
      background: var(--SecondaryBackgroundColor, #fff);
      box-shadow: 0px 60px 120px 0px rgba(38, 51, 77, 0.06);

      .title {
        color: var(--TextColor, #383941);
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 33.6px */
      }
    }

    .chartCard {
      border-radius: 8px;
      background: var(--SecondaryBackgroundColor, #fff);
      box-shadow: 0px 60px 120px 0px rgba(38, 51, 77, 0.06);
      padding: 16px 12px;
    }
  }

  .hide {
    width: 0;
    opacity: 0;
    margin: 0;
    padding: 0;
    height: 0;
  }
}
