.container {
  border-radius: 8px;
  background: var(--SecondaryBackgroundColor, #fff);
  box-shadow: 0px 60px 120px 0px rgba(38, 51, 77, 0.06);
}

.txt01 {
  color: var(--TextColor, #383941);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}

.sensorDetails {
  border: 1px solid #e8e8e8;
  border-radius: 8px;
}

.txt02 {
  color: var(--SecondaryTextColor, #69768b);
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}

.selectedFieldContainer {
  border-radius: 8px;
  background: rgba(232, 232, 232, 0.28);
}

.text03 {
  color: var(--SecondaryTextColor, #69768b);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
